import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Navbar } from 'react-bootstrap';
import { AccountPicker } from './AccountPicker';
import { IconButton, Chip, Typography, Drawer } from '@mui/material';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import FeedbackCollector from './FeedbackComponent';

import CoPresentIcon from '@mui/icons-material/CoPresent';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoutIcon from '@mui/icons-material/Logout';
import stylesNav from '../styles/NavigationBar.module.css'
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TableRowsIcon from '@mui/icons-material/TableRows';


export const NavigationBar = () => {
    const [showProfilePicker, setShowProfilePicker] = useState(false);
    const [feedbackForm, setFeedbackForm] = useState()
    const { instance } = useMsal();

    let currentEmail

    if (instance !== null) {
        try {
            let activeAccount = instance.getActiveAccount();
            currentEmail = String(activeAccount.username)
        } catch (MSALerror) {
            console.log("MSAL Error: " + MSALerror)
        }
    }


    const [open, setOpen] = useState(false)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    let adminList

    if (currentEmail) {
      if (currentEmail.includes("adm")) {
        adminList = (
          <List>
            <ListItem key={"AdminPowerAutomate"} disablePadding>
              <ListItemButton className={stylesNav.TypeStyle} href='/admin/PowerAutomate'>
                <ListItemIcon>
                  <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                </ListItemIcon>
                <ListItemText primary={"Power Automate"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"AdminTableMetadata"} disablePadding>
              <ListItemButton className={stylesNav.TypeStyle} href='/admin/TableMetadata'>
                <ListItemIcon>
                  <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                </ListItemIcon>
                <ListItemText primary={"Tabellen Metadaten"} />
              </ListItemButton>
            </ListItem>
          </List>
        )
      }
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
              <ListItem key={"Zeiterfassung"} disablePadding>
                <ListItemButton className={stylesNav.TypeStyle} href='/'>
                  <ListItemIcon>
                    <AccessAlarmIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Zeiterfassung"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Zeiteinträge"} disablePadding>
                <ListItemButton className={stylesNav.TypeStyle} href='/Meine Zeiteinträge'>
                  <ListItemIcon>
                    <RequestPageIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Zeiteinträge"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Anwesenheit"} disablePadding>
                <ListItemButton className={stylesNav.TypeStyle} href='/Anwesenheit'>
                  <ListItemIcon>
                    <CoPresentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Anwesenheit"} />
                </ListItemButton>
              </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"Abwesenheit"} disablePadding>
                <ListItemButton className={stylesNav.TypeStyle} href='/Abwesenheit'>
                  <ListItemIcon>
                    <AlarmOffIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Abwesenheit"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Vertretung"} disablePadding>
                <ListItemButton className={stylesNav.TypeStyle} href='/Vertretung'>
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Vertretung"} />
                </ListItemButton>
              </ListItem>
          </List>
          {adminList}
        </Box>
      );

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleFeedBack = () => {
        setFeedbackForm(
            <FeedbackCollector closeCallback={closeCallback}></FeedbackCollector>
        )
    }

    const closeCallback = () => {
        setFeedbackForm(null)
    }

    const handleLogoutRedirect = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    };

    const handleSwitchAccount = () => {
        setShowProfilePicker(!showProfilePicker);
    };

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <Navbar className={stylesNav.navbarStyle}>
                <AuthenticatedTemplate>
                <div  style={{width: "15px"}}></div>
                <IconButton onClick={toggleDrawer(true)}><TableRowsIcon></TableRowsIcon></IconButton>
                <div  style={{width: "10px"}}></div>
                <a className={stylesNav.TypeStyle} href="/">
                    <AccessAlarmIcon sx={{marginRight: "3px"}}></AccessAlarmIcon><Typography variant='p'>Zeiterfassung</Typography>
                </a>
                    <div className="collapse navbar-collapse justify-content-end">
                        <p style={{marginTop: "auto", marginBottom: "auto"}}>Beta v0.9.8.2</p>
                        <IconButton onClick={handleFeedBack}>
                            <FeedbackIcon></FeedbackIcon>
                        </IconButton>
                        <Chip color="secondary" onClick={handleSwitchAccount} label={activeAccount ? activeAccount.name : 'Unknown'} variant="outlined" />
                        <IconButton onClick={handleLogoutRedirect}>
                            <LogoutIcon></LogoutIcon>
                        </IconButton>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div  style={{width: "15px"}}></div>
                    <img src='https://nms.de/wp-content/uploads/NMS-NewMediaService-Dreiecke-weiss.svg' width={"20px"} alt="NMS Logo"/>
                </UnauthenticatedTemplate>
                <div style={{width: "15px"}}></div>
            </Navbar>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            <AccountPicker show={showProfilePicker} handleSwitchAccount={handleSwitchAccount} />
            {feedbackForm}
        </>
    );
};